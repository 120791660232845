@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*font-family: 'DM Serif Display', serif;*/

/*font-family: 'Roboto Slab', serif;*/


body {
    font-family: 'Poppins', sans-serif;

}

.Container {
    max-width: 1175px;
    margin: 0 auto !important;
    position: relative;
}

.MainHeader {
    position: sticky;
    background: #FFFFFF;
    top: 0;
    z-index: 99999;
    box-shadow: 0px 2px 4px #0000001A;
}

.NavBarTen .header__middle__menus {
    justify-content: space-between !important;
    top: 0px !important;
    right: 0px !important;
}


.SliderElevenC .BannerText .bannerTitle {
    font-family: 'DM Serif Display', serif;
    font-weight: 400 !important;
}

.SlahTableCtn {
    background: #DB9E30 url(../imgs/SalahTablebackground.png);
    background-size: 80%;
    background-position: center;
    object-fit: contain;
    padding-top: 20px;
    border-radius: 4px;
}


.SlahTableCtn .MuiTableCell-root {
    font-family: 'Roboto Slab', serif;
    font-size: 18px;
    font-weight: 600;
}

.SlahTableCtn .SlatTableFooter {
    color: #fff;
    font-size: 12px;
    font-style: italic;
    padding: 0 25px 20px;
}

.SalatTableContainer .SalatTaleTitle {
    font-size: 24px !important;
    justify-content: left !important;
    height: 40px !important;
    padding: 0 30px !important;
    font-family: 'Roboto Slab', serif;
    color: #fff !important;
    text-transform: initial !important;
    padding-bottom: 14px !important;
}

.CtaCustomCard {
    background: #fff;
    padding: 30px;
}

.CtaCustomCard .CtaCustomCardLogo {
    text-align: center;
    border-bottom: #D2D3D8 1px solid;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.CtaCustomCard .CtaCustomCardLogo img {
    width: 140px;
}

.CtaCustomCard h2 {
    font-size: 16px;
    font-weight: 600;
    color: #DB9E30;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.CtaCustomCard h1 {
    font-size: 64px;
    font-weight: 300;
    color: #B9278A;
    font-family: 'DM Serif Display', serif;
    margin: 0;
    line-height: 80px;
    margin-bottom: 14px;

}

.CTAOne h2,
.CTAOne p {
    color: #0B132B !important;
    font-family: 'Poppins', sans-serif;
}

.CtaButtons .MsInputButtonMainContainer {
    margin-top: 8px;
}

.CTAOne a {
    margin-top: 46px !important;
    border-radius: 4px !important;
}

.FeaturedEvents .CardSevenContainer .GreenText {
    color: #B9278A !important;
}

.adsCard {
    background: #F2F2F2;
    color: #E1E6EB;
    font-size: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    text-decoration: none;

}

.adsCard span {
    display: block;
}

.adsCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.TitleOneContainer .TitleOneTextContainer {
    text-transform: initial !important;
    font-family: 'Roboto Slab', serif;
    font-size: 36px !important;

}

.TitleOneContainer .TitlOneeLineContainer {
    display: none !important;
}

.TitleOneContainer {
    border-bottom: #D2D3D8 1px solid;
    padding-bottom: 10px;
}

.SectionMainContainer .SectionInnerContainer .CardEleven:hover .CardElevenInfoContainer .CardElevenreadmore {
    color: #B9278A !important;
}

.SupportUsSection {
    background: url(../imgs/academic-programs-background.jpg);
    padding: 80px 0;
    background-size: 100%;
    background-position: bottom center;
}

.SupportUsSection .SupportUsLeftCol h1 {
    color: #fff;
    font-size: 56px;
    font-family: 'DM Serif Display', serif;
    font-weight: 400;
    margin: 0;
}

.SupportUsSection .SupportUsLeftCol p {
    color: #fff;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.SupportUsSection .SupportUsLeftCol img {
    width: 80px;
}

.percentageCard {
    padding: 32px;
    border-radius: 4px;
}

.percentageCard h2 {
    color: #fff;
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin: 0;
}

.percentageCard p {
    color: #fff;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin: 0;
}

.percentageCard label {
    display: inline-block;
    width: 50px;
    height: 3px;
    border-radius: 2px;
}

.RedCard label {
    background: #DB9E30;
}


.RedCard {
    background: #B9278A;
}

.YellowCard {
    background: #DB9E30;
}

.YellowCard label {
    background: #B9278A;
}

.FeaturedServicesCard {
    text-align: center;
}

.FeaturedServicesCard img {
    width: 175px;
    height: 175px;
    border-radius: 100px;
}

.FeaturedServicesCard h2 {
    font-size: 26px;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    color: #B9278A;
}

.FeaturedServicesCard p {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #676C7B;
}

.AverageCard {
    text-align: center;
}

.AverageCard h3 {
    font-size: 24px;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    color: #FFFFFF;
    margin: 0;
}

.AverageCard h2 {
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #FFFFFF;
    margin: 0;
}

.AverageCard p {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #4A3000;
    margin: 0;
    max-width: 300px;
    margin: 0 auto;
}

.AverageSection {
    background: url(../imgs/averageBg.jpg);
    padding: 80px 0;
    background-size: 90%;
    background-position: bottom;
}

.InnerPageTitle {
    font-size: 60px;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: #B9278A;
    margin: 0;
    text-align: center;
    margin-bottom: 50px;
}

.InnerPageTitleGrey {
    font-size: 60px;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: #0B132B;
    margin: 0;
    text-align: center;
    margin-bottom: 50px;
}

.PageTitleSection {
    max-width: 600px;
    margin: 0 auto;
}

.PageTitleSection p {
    font-size: 20px;
    font-weight: 400px;
    color: #0B132B;
}

.PageTitleSection h2 {
    font-size: 48px;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: #0B132B;
    margin: 0;
    text-align: center;
}


.AboutBanner {
    height: 410px;
}

.AboutBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.AboutContents h1 {
    font-size: 36px;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: #0B132B;
    margin: 0;
    border-bottom: #D2D3D8 1px solid;
    margin-bottom: 20px;
    padding-bottom: 15px;
    margin-top: 80px;
}

.AboutContents h2 {
    font-size: 24px;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: #0B132B;
    margin: 0;
    border-bottom: #D2D3D8 1px solid;
    margin-bottom: 20px;
    padding-bottom: 15px;
}

.AboutContents p {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #0B132B;
    line-height: 30px;

}

.AboutSections {
    margin-top: 80px;
}

.DirectorCard .DirectorCardImg {
    overflow: hidden;
}

.DirectorCard .DirectorCardImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.DirectorCard h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    margin-top: 4px;
}

.DirectorCard p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #676C7B;
    font-style: italic;
}

.CommunityText p {
    font-size: 14px;
    line-height: 24px;
    color: #0B132B;
}

.MuiAccordionSummary-root.Mui-expanded {
    border-bottom: none !important;
}

.RoundedImg img {
    width: 100%;
    border-radius: 100px;
}


.QuickLinkPannel {
    background: #F8F8F8;
    border-left: #B9278A 3px solid;
    padding: 24px;

    position: sticky;
    top: 0;

}

.QuickLinkPannel h2 {
    margin: 0;
    font-size: 34px;
    font-weight: 700;
    color: #0B132B;
}

.QuickLinkPannel li a {
    font-size: 20px;
    font-weight: 500;
    color: #0B132B;
    margin-top: 32px;
    display: block;
}

.QuickLinkPannel li a:hover {
    color: #B9278A;
}

.ListingCard img {
    width: 144px !important;
    height: 144px !important;
    border-radius: 100px;
}

.ListingCard .CardSixteen .CardSixteenImg {
    min-width: 144px;
    height: 144px;
    background-color: transparent;
    position: relative;
}

.eventlistcard .videoIcon {
    display: none;
}

.eventlistcard .CardSixteen .VideoCardOnehover_over {
    display: none;
}

.CardSixteen {
    border: none !important;
}

.Socialsection {
    display: flex;
    justify-content: space-between;
}

.Socialsection .SocialMediaOne .SocialCtn a img {
    width: 45px;
}

.backBtn span {
    color: #DB9E30 !important;
}

.eventDetailsContetns h1 {
    font-size: 34px;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: #0B132B;
}

.eventDetailsBanner {
    height: 538px;
    overflow: hidden;
}

.eventDetailsBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.ListingVideoCard img {
    width: 144px !important;
    height: 144px !important;
    border-radius: 100px;
}

.ListingVideoCard .CardSixteen .CardSixteenImg {
    min-width: 144px;
    height: 144px;
    background-color: transparent;
    position: relative;
}

.ListingVideoCard .videoIcon {}

.ListingVideoCard .CardSixteen .VideoCardOnehover_over {
    border-radius: 100px;
}

.ListingVideoCard {
    border: none !important;
}

.ListingVideoCard .CardSixteenReadmore {
    display: none;
}

.FaqSection .MuiIconButton-label .MuiSvgIcon-root {
    color: #B9278A !important;
}

.CtaOneCardDark .CTAOne h2 {
    color: #fff !important;
    ;
}

.CtaOneCardDark .CTAOne p {
    color: #fff !important;
    ;
}

.PageTitleTwoMainContainer {
    justify-content: center !important;
}

.PageTitleTwoMainContainer .PageSubTitleTwo {
    display: flex;
    justify-content: center;
}

.PageTitleTwoMainContainer .PageTitleTwoTextContainer .PageSubTitleTwo .MuiTypography-root {
    color: #0B132B !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.PageTitleTwoMainContainer .PageTitleTwoTextContainer .PageSubTitleTwo .MuiBreadcrumbs-separator {
    color: #0B132B !important;
}

.ResourceCard {
    border: #E4E8EC 1px solid;
    display: flex;
    border-radius: 4px;
    background: #fff;
}

.ResourceCard .Rc_letf {
    max-width: 180px;
    background: #fbfcfc;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
}

.ResourceCard .Rc_letf img {
    width: 130px;
}

.ResourceCard .Rc_Right {
    padding: 20px;
    flex: 1;
    width: 80%;
}


.ResourceCard .Rc_Right h2 {
    color: #0B132B;
    font-size: 22px;
    font-weight: 600;

}


.ResourceCard .Rc_Right h2 span {
    display: block;

}

.ResourceCard .Rc_Right .MuiButtonBase-root {
    color: #DB9E30;
    font-size: 14px;

}

.ResourceCard .Rc_Right .MuiButtonBase-root svg {
    color: #DB9E30;
    font-size: 20px;
    margin-left: 8px;

}

.AcProgramCard .AcProgramImg {
    height: 196px;
    overflow: hidden;
}

.AcProgramCard .AcProgramImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.AcProgramCard {
    background: #fff;
}

.AcProgramCard .AcProgramCardInfo {
    padding: 16px;
    border: #E4E8EC 1px solid;
}

.AcProgramCard .AcProgramCardInfo .ProgDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AcProgramCard .AcProgramCardInfo .ProgDate label {
    font-size: 14px;
    color: #B9278A;
}

.AcProgramCard .AcProgramCardInfo .ProgDate span {
    font-size: 14px;
    color: #fff;
    background: #B9278A;
    border-radius: 4px;
    padding: 3px 8px;
    font-weight: 500;
}

.AcProgramCard .AcProgramCardInfo h3 {
    font-size: 20px;
    margin: 0;
}

.AcProgramCard .AcProgramCardInfo .MuiButtonBase-root {
    background: #DB9E30;
    color: #fff;
    width: 100%;
    padding: 0 15px;
    height: 48px;
    margin-top: 44px;

}

/* ProcessedSuccessfully CSS END */

.EventDetailsModalContainer {
    max-width: 1175px;
    margin: 0 auto;
    padding-top: 20px;
}

.EventDetailsModalContainer .ImgCol {
    position: relative;
    background: #E4E8EC;
    min-height: 576px;
    overflow: hidden;
}

.EventDetailsModalContainer .ImgCol .dtlImg {
    width: 100%;
    height: 100%;
    position: absolute;
}

.EventDetailsModalContainer .ImgCol .ShareCol {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px;
}

.EventDetailsModalContainer .ImgCol .ShareIcon {
    background: #fff;
    color: #444444 !important;
    font-size: 14px;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    text-align: center;
    position: relative;
    margin-left: 14px;
}

.EventDetailsModalContainer .ImgCol .ShareIcon:after {
    content: " ";
    position: absolute;
    left: -7px;
    top: 10px;
    border-top: 8px solid transparent;
    border-right: 8px solid white;
    border-left: none;
    border-bottom: 8px solid transparent;
}

.EventDetailsModalContainer .ImgCol .ShareIcon svg {
    color: #444444;
    font-size: 14px;

}


.EventDetailsModalContainer .SocialIcon {
    display: flex;
    align-items: center;
}

.EventDetailsModalContainer .ImgCol .ShareCol .SocialIcon a img {
    width: 44px;
}

.DtlContentLeft {
    border-right: #E4E8EC 1px solid;
    padding: 30px;
    position: relative;
    overflow-y: auto;
}

.DtlContentLeft h2 {
    font-size: 16px;
    color: #0B132B;
    text-transform: uppercase;
    letter-spacing: 0;
}

.DtlContentLeft p {
    font-size: 16px;
    color: #676C7B;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 8px;
}

.DtlRightContainer {
    padding: 30px;
}

.DtlRightContainer .DtlDate {
    color: #B9278A;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

.DtlRightContainer h1 {
    color: #0B132B;
    font-size: 34px;
    line-height: 40px;
    font-weight: 700;
    margin-top: 8px;
}

.DtlStatusCol {
    margin-top: 24px;
    margin-bottom: 30px;
}

.DtlStatusCol li {
    display: flex;
    color: #0B132B;
    font-size: 20px;
    padding: 8px 0;
    align-items: center;
}

.DtlStatusCol li svg {
    color: #676C7B;
    margin-right: 8px;
}

.SpeekerContainer {
    display: block !important;
}

.SpeekerContainer h1 {
    font-size: 24px;
    color: #0B132B;
    font-weight: 600;
    font-family: 'Roboto Slab', serif;

}

.Speekerchip {
    display: flex;
    border: #E4E8EC 1px solid;
    padding: 12px;
    align-items: center;

}

.SpeekerIcon {
    background: #EEF6F1;
    width: 44px;
    height: 44px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #56B149;
    margin-right: 12px;
    overflow: hidden;
}

.SpeekerIcon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SpeekerDetails h2 {
    font-size: 16px;
    font-weight: 700;
    color: #0B132B;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
    letter-spacing: 0;

}

.SpeekerDetails p {
    font-size: 14px;
    font-weight: 400;
    color: #4C5E67;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
    letter-spacing: 0;
    line-height: 24px;
}

.LocationContainer h1 {
    font-size: 16px;
    color: #0B132B;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0;
}

.accordionContainer {
    border: #E4E8EC 1px solid;
}

.accordionContainer {}

.accordionContainer .MuiIconButton-label .MuiSvgIcon-root {
    color: #676C7B;
}

.accordionContainer .accordionTitle {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.accordionContainer .accordionTitle img {
    margin-right: 13px;
}

.accordionContainer .MuiAccordionDetails-root {
    display: block;
    padding: 8px 16px 16px;
}

.accordionContainer .MuiAccordionDetails-root a {
    color: #0590FA;
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 25px;
}

.accordionContainer .MuiAccordionDetails-root p {
    color: #0B132B;
    text-decoration: none;
    font-size: 16px;
}

/* accordionContainer CSS END */

.EventListng .CardSevenContainer .UpcomingEventContents .EventCardSubTitle {
    font-size: 34px;
    font-weight: 700;
    color: #0b132b;
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
}

.EventListng svg {
    display: none !important;
}





.PastEvents {
    display: flex;
    border-bottom: #E4E8EC 1px solid;
    padding-bottom: 32px;
    margin-bottom: 32px;
}

.PastEvents .Past_left {
    border-radius: 100px;
    background: #0B132B;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 144px;
    min-width: 144px;
    flex-wrap: wrap;
    width: 144px;
    height: 144px;
}

.PastEvents .Past_left span {
    font-size: 14px;
    color: #00D4FF;
    text-transform: uppercase;
    display: block;
    width: 100%;
    text-align: center;
}

.PastEvents .Past_left h4 {
    font-size: 48px;
    color: #fff;
    margin: 0;
    line-height: 50px;
}


.PastEvents .Past_Right {
    padding: 0 20px;
    flex: 1;
    width: 80%;
}


.PastEvents .Past_Right h2 {
    color: #0B132B;
    font-size: 22px;
    font-weight: 600;

}


.PastEvents .Past_Right h2 span {
    display: block;

}

.PastEvents .Past_Right .MuiButtonBase-root {
    color: #DB9E30;
    font-size: 14px;

}

.PastEvents .Past_Right .MuiButtonBase-root svg {
    color: #DB9E30;
    font-size: 20px;
    margin-left: 8px;

}

.DtlDate {
    color: #B9278A;
}

.PageTitleTwoMainContainer .PageTitleTwoTextContainer .PageTitleTwoMain {
    text-transform: initial !important;
    font-family: 'Roboto Slab', serif !important;
}

.TosContainer h1 {
    font-size: 34px;
    color: #1B2121;
}

.TosContainer h1 span {
    font-size: 14px !important;
    display: block;
    font-weight: 400;
    color: #676C7B;
}

.TosContainer h2 {
    font-size: 24px;
    color: #1B2121;
}

.TosContainer p {
    font-size: 20px;
    color: #676C7B;
    line-height: 32px;
}

.TosContainer a {
    color: #004FE0;
}

.AcadProgHome .SectionMainContainer {
    background: url(../imgs/academic-programs-background.jpg);
    background-size: 100%;
    background-position: calc(20%);

}

.PageTitleTwoMainContainer .PageTitleTwoTextContainer .PageSubTitleTwo a {
    color: #000 !important;
    padding: 5px;
    display: inline-block;

}

.SliderElevenC .BannerImgContainer {
    position: relative !important;
    height: 640px !important;
}

.SliderElevenC .MainBannerInner {
    width: 100% !important;
    height: 640px !important;
}

.SliderElevenC .BannerText {
    position: absolute;
    z-index: 999;
    bottom: 130px !important;
    left: 0;
    background: transparent !important;
    width: 100%;
}

.NavBarContainer{
    position: sticky !important;
    z-index: 99999;
    top: 96px;
}

.NavBarContainer .menu-item a:hover {
    color: #fff !important;
}

.NavBarContainer .menu-item .sub__menus li:hover a {
    color: #fff !important;
    background: #f7f9fa;
}


.NavBarContainer .menu-item .sub__menus li a:hover {
    color: #B9278A !important;
}

.FaqSource {
    font-size: 14px;
    color: #0B132B;
    margin-top: 15px;
}

.FaqSource a {
    color: #B9278A;
    text-decoration: none;
}

.FooterMainBoxBottomMainContainer .FooterMainBoxBottomGrey {
    display: none !important;
}

.SlahTableCtn .MuiTableCell-root {
    border: none !important;
}

.SlahTableCtn .MuiTableRow-root {
    border-bottom: #e6b766 1px solid;
}

.SlahTableCtn .MuiTableRow-root:first-child {
    border-top: #e6b766 1px solid;
}

.SlahTableCtn .SalatTableContainer .MuiTableContainer-root .MuiTableCell-root {
    padding: 13.4px 30px !important;
}

.input_container .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #B9278A !important;
}

.MsInputButtonMainContainer .MsInputButton svg {
    font-size: 26px !important;
    margin-left: 3px !important;
}

.VideoCardOneInner:hover .VideoCardOneDetails h2 {
    color: #B9278A !important;
    ;
}

.ExpansionFooterContainer .SalatTableContainer .MuiTableContainer-root .MuiTable-root .MuiTableRow-root .MuiTableCell-root {
    padding: 6px 0 !important;
    border-bottom: #2c3246 1px solid;
}

.FooterEightAboutText p {
    margin-bottom: 45px !important;
}

.FooterEightListingContactInfo {
    padding-bottom: 24px !important;
}


.CardSevenContainer .UpcomingEventContents .EventCardSubTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.main-nav ul.menuq2 {
    border-bottom: 3px solid #B9278A !important;

}

.NavBarNineRight .NavBarNineContact svg {
    color: #b0b5c7 !important;
    font-size: 22px !important;
}

.VideoCardOne .VideoCardOneInfoContainer .VideoCardOneDetails {
    padding: 20px;
    min-height: 60px;
}

.VideoCardOne .VideoCardOneInfoContainer .VideoCardOneDetails h2 {
    font-size: 20px;
    margin: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}




/* -------------------New Footer---------------------------- */
.FooterContainer {
    max-width: 1175px;
    margin: 0 auto;
}

.FooterMainBoxBottomMainContainer .FooterMainBoxBottomWhite {
    background: #fff;
    margin-top: 14px !important;
    padding: 15px;
}

.FooterLogo{
    display: flex;
    align-items: center;
}

.FooterLogo img {
  margin-right: 20px;
}

.FooterTop {
    display: flex;
    justify-content: space-between;
}

.borderLine{
    background: #676C7B;
    height: 1px;
    margin-top: 20px;
}

.Footercontent h1 {
    color: #fff;
    font: normal normal bold 23px Roboto Slab;
    color: #FFFFFF;
    opacity: 80%;
    font-size: 24px;
    margin:0;
}

.Footercontent h4 {
    color: #FFFFFF;
    opacity: 80%;
    font-size: 14px;
    font-weight: 400;
    margin:0;
}

.FooterTopSocialicons {
    
}

.FooterSocialicons img {
    padding-left: 20px;
    padding-top: 30px;
    height: 52px;
}

.FooterAdressContent h3 {
    color: #FFFFFF;
    margin-top: 80px;
    opacity: 80%;
    font: normal normal 600 16px Poppins;
    padding-bottom: 10px;
}
.MsInputButtonMainContainer .MsInputButton {
    font-size: 13px !important;
    text-transform: uppercase;
}
.FooterAdressContent p {
    font: normal normal normal 15px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 75%;
    font-weight: 300;
    line-height: 9px;
}

.FooterContactContent h3 {
    color: #FFFFFF;
    margin-top: 80px;
    opacity: 80%;
    font: normal normal 600 16px Poppins;
    padding-bottom: 5px;
}

.Calldetails p {
    color: #FFFFFF;
    font: normal normal normal 14px Poppins;
    opacity: 75%;
    padding-left: 14px;
    line-height: 5px;
}
.Calldetails img {
    height: 23px;
    padding-top: 6px;
    opacity: 80%;
}
.Calldetails {
    display: flex;
}
.Emaildetails {
    display: flex;
}
.Emaildetails p {
    color: #fff;
    font: normal normal normal 14px Poppins;
    opacity: 75%;
    line-height: 5px;
    padding-left: 14px;
}
.Emaildetails img {
    height: 23px;
    padding-top: 5px;
    opacity: 80%;
}
.FooterBody {
    display: flex;
}
.Sociallinksdetails p {
    color: #fff;
    font: normal normal 600 14px/24px Poppins;
    letter-spacing: 0.56px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1
}
.Sociallinksdetails h3 {
    color: #FFFFFF;
    margin-top: 80px;
    opacity: 80%;
    font: normal normal 600 16px Poppins;
}

.Footerbottomdetails .divider {
    display: flex;
    justify-content: space-between;

}

.Footerbottomdetails p {

    font: normal normal normal 14px/16px Poppins;
    color: #A4A7B0;
}

.Getdirectionbtn{
    margin-top: 33px;
}

/* --------------------New Footer End---------------------------- */


.CardEleven .CardElevenInfoContainer .CardElevenDetails h2 {
    font-size: 20px;
    margin: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.FooterSocialIcon .btn{
    height: 48px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 20px;
    margin-bottom: 16px;
}

.FooterSocialIcon .btn img{
    margin-right: 8px;
}

.FooterSocialIcon .fbButton{
    background: #1877F2;
}

.FooterSocialIcon .YoutubeButton{
    background: #F44336;
}

.ExpansionFooterContainer{
    background: #000000;
    padding-top: 20px;
}

/* ========== SALAH TIME CSS =========== */


.SalatTableContainer .SalatTaleMainTitle {
    font-size: 24px !important;
    justify-content: left !important;
    height: 40px !important;
    padding: 0 30px !important;
    font-family: 'Roboto Slab', serif;
    color: #fff !important;
    text-transform: initial !important;
    padding-bottom: 14px !important;
    border-bottom: #e6b766 1px solid;
}


.SalatTableContainer .SalatTaleTitleRowTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13.4px 30px !important;
    border-bottom: #e6b766 1px solid;
}

.SalatTableContainer .SalatTaleTitleRowTitle h1{
    font-family: 'Roboto Slab', serif;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-align: right;
    margin: 0 !important;
}

.SalatTableContainer .SalatTaleTitleRowTitle h1:first-child{
    width: 44%;
}


.SalatTableContainer .SalatTaleCol{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13.4px 30px !important;
    border-bottom: #e6b766 1px solid;
}

.SalatTableContainer .SalatTaleCol li{
    font-family: 'Roboto Slab', serif;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-align: right;
}

.SalatTaleCol li:first-child{
    text-align: left;
    width: 40%;
}
