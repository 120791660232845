
.BannerTowContainer{
    position: relative;
}

.BannerDetailsContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    height: 100%;
}

.BannerDetailsContainer .BannerDetails{
    max-width: 650px;
    text-align: center;
}

.BannerDetailsContainer .BannerDetails h2{
    font-family: 'DM Serif Display', serif;
    font-weight: 400 !important;
    color: #fff;
    display: inline-block;
    font-size: 60px;
    margin: 0;
}

.BannerDetailsContainer .BannerDetails p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    max-width: 600px;
    font-family: Poppins,sans-serif;
    margin: 0 auto;
    margin-top: 20px;
}

.BannerTowContainer .BannerImg{
    height: 600px;
}

.BannerTowContainer .BannerImg img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.BannerDetailsContainer .BannerButton{
    min-width: 152px;
    min-height: 50px;
    opacity: 1;
    margin-top: 32px!important;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    background: #B9278A;
    border-radius: 0 !important;
    color: #fff;
    margin: 0 auto;
}

.BannerAlfa{
    background: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.MuiIconButton-sizeMedium{
    border-radius: 0 !important;
    background: #B9278A !important;
}



@media (max-width: 768px) {
    .BannerDetailsContainer .BannerDetails{
        padding: 0 25px;
    }

    .BannerDetailsContainer .BannerDetails h2 {
        font-size: 40px;

    }

    .BannerTowContainer .BannerImg{
        height: 500px;
    }

    .BannerDetailsContainer .BannerDetails p {
        max-width: 300px;
    }
}

