
@media (max-width: 1200px) {
.SupportUsSection {
    padding: 80px 25px;
}

}

@media (max-width: 990px) {
    .NavBarNineContainer {
        padding: 15px 25px;
    }

    .SupportUsSection{
        padding:  25px;
    }
    .SalatTableContainer .MuiTable-root {
        width: 420px;
        overflow-x: scroll;
    }

    .CTAOne a {
        margin-top: 76px !important;
        border-radius: 4px !important;
    }

    .CardEleven .CardElevenInfoContainer .CardElevenDetails h2 {
        font-size: 20px;
        margin: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

}

@media (max-width: 768px) {
    .FooterEightMainContainer .FooterEightContainer {
        margin: 0 auto;
        padding: 0 25px;
        display: block;
    }

    .NavBarNineRight .NavBarNineContact {
        margin-right: 20px;
        display: none !important;
    }

    .FooterEightTopContainer .FooterEightTopLogoCol {
        display: block;
        text-align: center;
    }

    .FooterEightTopContainer .FooterEightTopLogoCol img {
        margin-right: 0;
        display: inline-block;
    }

    .FooterEightMainContainer .FooterEightSocial {
        padding-top: 20px;
        justify-content: center;
        width: auto;
        margin: 0;
    }

    .FeaturedServicesCard {
        text-align: center;
        padding: 0 25px;
    }

    .Container {
        padding: 0 25px;
    }

    .PastEvents {
        text-align: center;
        display: block;
    }

    .PastEvents .Past_left {
        margin: 0 auto;
    }

    .PastEvents .Past_Right {
        padding: 0 0px;
        width: auto;
        margin-top: 20px;
    }

    .adsCard {
        width: 100%;
    }

    .CardSevenContainer .UpcomingEventContents {
        padding: 20px;
        width: 88%;
        text-align: left;
    }

    .NavBarTenContainer,
    .NavBarTenContainer .HeaderOneContainer .HeaderOnesiteLogo {
        height: 50px;
    }


    .FooterEightMainContainer .PrivacySection {
        display: block;
    }

    .FooterEightMainContainer .PrivacySectionRight {
        text-align: center;
        padding-top: 15px;
    }

    .NavBarTen .menu-item a {
        height: 34px;
    }

    .SlahTableCtn .SalatTableContainer .MuiTableContainer-root .MuiTableCell-root {
        padding: 12px 15px !important;
    }

    .MuiLink-underlineHover {
        text-align: center;
    }

    .ListingCard .CardSixteen .CardSixteenImg {
        text-align: center;
    }

    .CardSixteen .CardSixteenReadmore {
        justify-content: center;
    }

    .MsInputButtonMainContainer .MsInputButton {
        width: 100% !important;
    }

    .PageTitleTwoMainContainer .PageTitleTwoTextContainer .PageTitleTwoMain {
        text-align: center;
    }

  
    .SliderElevenC .BannerText .bannerTitle {
        padding: 0 25px;
        font-size: 32px !important;
    }

    .SliderElevenC .BannerText .bannerDescrition {
        padding: 0 45px;
    }

    .SliderElevenC .BannerText {
        bottom: 110px !important;
    }

    .SliderElevenC .MainBannerInner {
        height: 420px !important;
    }

    .SliderElevenC .BannerImgContainer {
        position: relative !important;
        height: 420px !important;
    }

    .SliderElevenC .BannerTextInner .BannerInnerCtn {
        margin: 0 0;
        text-align: center;
    }

    .NavBarTen .header__middle__menus {
        top: 7px !important;

    }

    .ScrollHr{
        overflow-x: auto;
    }

    .ScrollHr .MuiGrid-container {
        width: 100%;
        display: flex;
        flex-wrap: initial;
        box-sizing: border-box;
    }

    .ScrollHr .CardEleven{
        width: 300px;
    }

    .ScrollHr .MuiGrid-grid-xs-4 {
        flex-grow: 0;
        max-width: 320px;
        flex-basis: 320px;
    }

    .ScrollHr .CardEleven .CardElevenInfoContainer .CardElevenDetails {
        height: 140px;
    }

    .ScrollHr .VideoCardOne{
        width: 300px;
    }

    .ScrollHr .FeaturedServicesCard{
        width: 300px;
    }

    .FooterContainer{
        padding: 0 20px;
    }

    .FooterLogo {
        display: block;
        text-align: center;
    }

    .FooterTop {
        display: block;
        text-align: center;
    }

    .FooterSocialicons img {
        padding: 10px;
        padding-top: 30px;
        height: 52px;
    }

    .Sociallinksdetails h3 {
        margin-top: 10px;
 
    }

    .FooterContactContent h3 {
        margin-top: 10px;
    }

    .Footerbottomdetails .divider {
        display: block;
        justify-content: space-between;
        text-align: center;
    }
    

}


@media (max-width: 460px) {
    .CardEleven .CardElevenInfoContainer .CardElevenDetails h2 {
        font-size: 20px;
        margin: 0 !important;
        overflow: visible;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
    }

   
}